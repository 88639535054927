<template>
  <div class="app-soon mt-16">
    <span
      class="soon-text m-0 border-dashed border rounded-8 py-4 px-12 mt-16 text-14 font-medium font-bbva"
      :class="soonClass"
    >
      {{ $t('SHARED.SOON') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppSoon',
  props: {
    isYellow: { type: Boolean, default: false },
  },
  computed: {
    soonClass() {
      return this.isYellow ? 'border-yellow-500 text-yellow-500' : 'border-blue-400 text-blue-700'
    },
  },
}
</script>

<style lang="scss" scoped>
.app-soon {
  .soon-text {
    width: fit-content;
  }
}
</style>
